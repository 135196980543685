import { ReplaySubject } from "rxjs";
import { handleError } from "src/handle-error.helper";
import { isEqual } from "lodash";

let betasFetchedOnce = false;
const betaSubjectRxjs = new ReplaySubject(1);

export function getBetasAsObservable() {
  fetchBetasIfNotFetched();
  return betaSubjectRxjs.asObservable();
}

function fetchBetasIfNotFetched() {
  if (!betasFetchedOnce) {
    refetchBetas(true).catch((err) => {
      if (err && err.status === 401) {
        // ignore
      } else {
        handleError(err);
      }
    });
  }
  betasFetchedOnce = true;
}

export function refetchBetas(passThrough401) {
  return SystemJS.import("fetcher!sofe")
    .then((fetcherModule) =>
      fetcherModule.fetchAsObservable("/betas", { passThrough401 }).toPromise()
    )
    .then((data) => data.betas)
    .then(setBetas);
}

function setBetas(betas) {
  if (!isEqual(window.betas, betas)) {
    betaSubjectRxjs.next(betas);
    window.betas = betas;
  }
  return betas;
}
